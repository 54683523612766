import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'LIBYANA-department-create-update-component-component',
  templateUrl: './department-create-update-component-component.component.html',
  styleUrls: ['./department-create-update-component-component.component.scss']
})
export class DepartmentCreateUpdateComponentComponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
