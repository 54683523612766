
<!-- <div *ngIf="departmentDetails">
  <mat-card class="max-w-full">
    <mat-card-header>
       <mat-card-subtitle>
        {{ departmentDetails.name }}
      </mat-card-subtitle>
     </mat-card-header>
     <mat-card-content style="border: 0.1px gray dotted;">
      <p>عدد الموظفين {{ departmentDetails.employeeCount }}</p>
      <p *ngIf="departmentDetails.parentDepartment">الادارة الرئيسية  {{ departmentDetails.parentDepartment || 'N/A' }}</p>
 
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-actions>
      <div class="flex">
     
        <span class="flex-1"></span>
        <button mat-button (click)="downloadFile(departmentDetails.id)">Download Employee File


          <mat-icon svgIcon="mat:download"></mat-icon>
        </button>
      </div>
    </mat-card-actions>
  </mat-card>

</div> -->

  <div class="flex-AUTO  w-full md:ltr:ml-6 md:rtl:mr-6 mt-6 md:mt-0">
    <div class="card">
      <div class="px-gutter py-4 border-b">
        <h2 class="title m-0">الادارات </h2>
      </div>

      <div class="px-gutter py-3">
        <!-- <div class="py-3 flex items-center">
          <div @scaleIn
               class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
            <mat-icon class="icon-sm" svgIcon="mat:chrome_reader_mode"></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{ departmentDetails?.employeeCount||'اختار الادارة ' }}</p>
            <p class="m-0 caption text-hint">  {{ departmentDetails?.name ||'اختار الادارة ' }}</p>
          </div>
        </div> -->



        <div class="py-3 flex items-center">
          <div @scaleIn
               class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
            <mat-icon class="icon-sm" svgIcon="mat:view_agenda"></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{ departmentDetails?.employeeCount ||'اختار الادارة ' }}</p>
            <p class="m-0 caption text-hint">  {{ departmentDetails?.name  ||'اختار الادارة ' }}</p>
          </div>
        </div>
        <div class="py-3 flex items-center"  *ngIf="departmentDetails?.parentDepartment">
          <div @scaleIn
               class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
            <mat-icon class="icon-sm" svgIcon="mat:web"></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{ departmentDetails?.parentDepartmentEmployeeCount||'اختار الادارة '  }}</p>
            <p class="m-0 caption text-hint">   {{ departmentDetails?.parentDepartment || 'N/A' }}</p>
          </div>
        </div>
        <div class="py-3 flex items-center"  *ngIf="departmentDetails?.parentDepartment">
          <div @scaleIn
               class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
            <mat-icon class="icon-sm"  (click)="downloadFile(departmentDetails?.id)"  svgIcon="mat:download"></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">الملف</p>
            <p class="m-0 caption text-hint">  تحميل </p>
          </div>
        </div>

        <div class="py-3 flex items-center"   >
          <div @scaleIn
               class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
            <mat-icon class="icon-sm"  (click)="downloadxls(departmentDetails)"  svgIcon="mat:attach_file"></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">تصدير الملف</p>
            <p class="m-0 caption text-hint">  تحميل </p>
          </div>
        </div>
        <div class="py-3 flex items-center" >
          <div @scaleIn
               class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
            <mat-icon class="icon-sm" (click)="downloadFile(departmentDetails?.id)" svgIcon="mat:download"></mat-icon>
          </div>
        
          <div @scaleIn
               class="w-10 h-10 rounded-full bg-danger/10 text-danger ltr:mr-3 rtl:ml-3 flex items-center justify-center">
            <mat-icon class="icon-sm"   (click)="confirmDelete(departmentDetails?.id)" svgIcon="mat:delete"></mat-icon>
          </div>
        
          <div @fadeInRight>
            <p class="m-0 body-1">الملف</p>
            <p class="m-0 caption text-hint">تحميل / حذف</p>
          </div>
        </div>
        
       
      </div>
    </div>

 
  </div>


  <form [formGroup]="empForm"   *ngIf="departmentDetails">
   

<div class="flex flex-row mt-3">
  <div class="flex-auto border-dotted" style="width: 50%; height: 200px; overflow-y: auto;">
   <mat-tree [dataSource]="dataSourceTree" [treeControl]="treeControl" class="example-tree">
     <mat-tree-node *matTreeNodeDef="let node; when: hasChild;" matTreeNodePadding
       [ngClass]="{
         'tree-node-level-0': node.level === 0,
         'tree-node-level-1': node.level === 1 && !node.isLeaf,
         'tree-node-level-2': node.level > 1 && !node.isLeaf,
         'tree-node-leaf': node.isLeaf
       }">
       <button mat-icon-button (click)="onNodeClick(node)"  >
         <mat-icon *ngIf="treeControl.isExpanded(node)" svgIcon="mat:expand_more"></mat-icon>
         <mat-icon *ngIf="!treeControl.isExpanded(node)" svgIcon="mat:chevron_right"></mat-icon>
       </button>
       {{ node.name }}
       <ng-container matTreeNodeOutlet></ng-container>
     </mat-tree-node>

     <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding
       [ngClass]="{
         'tree-node-level-0': node.level === 0,
         'tree-node-level-1': node.level === 1 && !node.isLeaf,
         'tree-node-level-2': node.level > 1 && !node.isLeaf,
         'tree-node-leaf': node.isLeaf
       }">
       <button mat-icon-button   (click)="onNodeClick(node)">
         <mat-icon *ngIf="treeControl.isExpanded(node)" svgIcon="mat:expand_more"></mat-icon>
         <mat-icon *ngIf="!treeControl.isExpanded(node)" svgIcon="mat:chevron_right"></mat-icon>
       </button>
       <button mat-icon-button (click)="toggleNodeSelection(node)"  >
         <mat-icon *ngIf="node.isSelected" svgIcon="mat:remove_circle"></mat-icon>
         <mat-icon *ngIf="!node.isSelected" svgIcon="mat:add_circle"></mat-icon>
       </button>
       {{ node.name }}
     </mat-tree-node>
   </mat-tree>
 </div>

 





 

</div>

<div class="flex flex-col sm:flex-row gap-2 sm:gap-6">

  <mat-form-field class="flex-auto">
    <mat-label>الادارة التابعة لها </mat-label>
    <mat-select formControlName="parentDepartmentId">
      <mat-option [value]="null">بلا</mat-option>
  
      <mat-option *ngFor="let department of departments" [value]="department.id"  >
        {{ department.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="empForm.get('parentDepartmentId')?.hasError('required')">Department is required</mat-error>
  </mat-form-field>
  


  <mat-form-field class="flex-auto">
        <mat-label>الاسم</mat-label>
        <input matInput formControlName="name" placeholder="Full Name">
        <mat-error *ngIf="empForm.get('name')?.hasError('required')">Name is required</mat-error>
      </mat-form-field>

      <mat-form-field class="flex-auto">
        <mat-label>البيان</mat-label>
        <input matInput formControlName="description" placeholder="description ">
        <mat-error *ngIf="empForm.get('description')?.hasError('required')"> is required</mat-error>
      </mat-form-field>
    </div>
    
    


    <button mat-raised-button type="button" (click)= "updateSelectedDepartments()">حفظ التعديل</button>
  </form>
  