


import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, filter } from 'rxjs';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';

import { ConfirmDialogComponent } from '../ShareComponent/confirm-dialog/confirm-dialog.component';
import { stagger, trigger, style, transition, animate, state } from '@angular/animations';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { Department } from 'src/assets/models/employees.model';
import { DepartmentNode, DepartmentsService, FlatNode } from './departments.service';
import { DepartmentCreateUpdateComponentComponentComponent } from './department-create-update-component-component/department-create-update-component-component.component';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { UserService } from '../user/user.service';

@Component({
  selector: 'LIBYANA-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss'],

  animations: [
    fadeInUp400ms,
    stagger40ms,stagger,scaleIn400ms,
      trigger('detailExpand', [
        state('collapsed', style({ height: '0px', minHeight: '0' })),
        state('expanded', style({ height: '*' })),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
    ],


  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})
@UntilDestroy() // إضافة هذا الديكور هنا

export    class DepartmentsComponent  implements OnInit, AfterViewInit  {
  treeControl: FlatTreeControl<FlatNode>;
  treeFlattener: MatTreeFlattener<DepartmentNode, FlatNode>;
  dataSourceTree: MatTreeFlatDataSource<DepartmentNode, FlatNode>;

  @Output() departmentSelected = new EventEmitter<any>();
  expoertEmp(exp:any) {
    console.log(exp)
  }

  checkUserRoles(roles: string[]): boolean {
    const allowedRoles = ['HR_Editor', 'HR_Admin', 'System_Administrator'];
    return roles.some(role => allowedRoles.includes(role));
  }
  constructor(private dialog: MatDialog, private member: DepartmentsService ,public user:UserService) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
    this.dataSourceTree = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  transformer = (node: DepartmentNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      departmentId: node.departmentId,
    };
  }

  getLevel = (node: FlatNode) => node.level;
  isExpandable = (node: FlatNode) => node.expandable;
  getChildren = (node: DepartmentNode): DepartmentNode[] => node.children || [];

  hasChild = (_: number, node: FlatNode) => node.expandable;

  onNodeClick(node: FlatNode): void {
    console.log(node);
    
      if (this.treeControl.isExpanded(node)) {
        this.treeControl.collapse(node);
      } else {
        this.treeControl.expand(node);
     
    }
    //     this.departmentSelected.emit({
    //   id: node.departmentId,
    //   name: node.name 
    // });
  }

  // toggleNodeSelection(node: FlatNode): void {

  //   node.isSelected = !node.isSelected;
  //   this.departmentSelected.emit({
  //     id: node.departmentId,
  //     name: node.name 
  //   });
  // }
  toggleNodeSelection(node: FlatNode): void {
    // Deselect all nodes
    this.treeControl.dataNodes.forEach(n => {
      n.isSelected = false;
    });
  
    // Select the clicked node
    node.isSelected = true;
  
    // Emit the selected node's details
    this.departmentSelected.emit({
      id: node.departmentId,
      name: node.name 
    });
  }
  
 
  subject$: ReplaySubject<  Department[]> = new ReplaySubject<   Department[]>(1);
  data$: Observable<  Department[]> = this.subject$.asObservable();
  customers: Department[];  

  ngOnInit() { 
    this.member.departments$.subscribe(data => {
      this.dataSourceTree.data = data;
    });  

    
  }


  ngAfterViewInit() {   
  }
  createCustomer() {
    const dialogConfig = new MatDialogConfig();
dialogConfig.width = '900px' ,dialogConfig.height='auto'; // Set the desired width here

this.dialog.open(
    DepartmentCreateUpdateComponentComponentComponent

  , dialogConfig) .afterClosed().subscribe((customer:   Department) => {
      /**
       * Customer is the updated customer (if the user pressed Save - otherwise it's null)
       */
      if (customer) {

    //     this.customers.unshift(customer);
    //  this.subject$.next(this.customers);
    // this.member.get   Department().subscribe((rep) => {
    //   this.subject$.next(rep);
    // });
    //   }
   } });
  }

  updateCustomer(customer:   Department) {
    this.dialog.open( DepartmentCreateUpdateComponentComponentComponent, {
      data: customer ,width:'900px' ,height:'auto'
    }).afterClosed().subscribe(updatedCustomer => {
      /**
       * Customer is the updated customer (if the user pressed Save - otherwise it's null)
       */
      if (updatedCustomer) {
        /**
         * Here we are updating our local array.
         * You would probably make an HTTP request here.
         */
        // const index = this.customers.findIndex((existingCustomer) => existingCustomer.id === updatedCustomer.id);
        // this.customers[index] = (updatedCustomer);
        // this.subject$.next(this.customers);
      }
    });
  }

  deleteCustomer(customer:    Department) {

      console.log(customer)
      this.dialog
      .open(ConfirmDialogComponent)
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {

            // this.member.delete   Department(customer).subscribe((rep)=> {
            //   console.log(rep);

            //   this.customers.splice(this.customers.findIndex((existingCustomer) => existingCustomer.id === customer.id), 1);
            //   this.selection.deselect(customer);
            //   this.subject$.next(this.customers);

            // });
          }



  });
}

handleFileUpload(event: any, subject: any): void {
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.onloadend = () => {
    const base64String = reader.result as string;
    subject.orderFileUpload = base64String;
  };
  reader.readAsDataURL(file);
}

handleAddClick(subject: any): void {
console.log(subject)
  let item = {
    id: subject.id,
       DepartmentId: subject.   DepartmentId,
     UserDepartmentDateTiket: subject. UserDepartmentDateTiket,
    orderFileUpload: subject.orderFileUpload
  };
  // this.member.update User Department(item).subscribe((rep) => {
  //   this.member.get   Department().subscribe((rep) => {
  //     this.subject$.next(rep);
 //  });
 // });
}
downloadFile(file: string) {
  const link = document.createElement('a');
  link.href = file;
  link.download = 'file';
  link.target = '_blank';
  link.click();
}
isImageType(file: string): boolean {
  return file.startsWith('data:image/');
}

isPdfType(file: string): boolean {
  return file.startsWith('data:application/pdf');
}

isWordType(file: string): boolean {
  return file.startsWith('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document')
    || file.startsWith('data:application/msword');
}

isExcelType(file: string): boolean {
  return file.startsWith('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    || file.startsWith('data:application/vnd.ms-excel');
}
getFormattedAmount(amount: any): string {
  const parsedAmount = parseFloat(amount);
  return isNaN(parsedAmount) ? '' : parsedAmount.toFixed(2);
}

  deleteCustomers(customers:    Department[]) {
    /**
     * Here we are updating our local array.
     * You would probably make an HTTP request here.
     */
    customers.forEach(c => this.deleteCustomer(c));
  }

  onFilterChange(value: string) {
  
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
 
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
 
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onLabelChange(change: MatSelectChange, row:    Department) {
    const index = this.customers.findIndex(c => c === row);
    this.customers[index] = change.value;
    this.subject$.next(this.customers);
  }
}
