import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuard } from './ShareComponent/auth.guard';
 import { HightChartsComponent } from './hight-charts/hight-charts.component';
import { AdminGuard } from './admin/guards/admin.guard';
// import { HighchartsComponent } from './ShareComponent/highcharts/highcharts.component';

const routes: VexRoutes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },

  {
    path: 'home',
    loadChildren: () => import('./pages/apps/help-center/help-center.module').then(m => m.HelpCenterModule),
  },

  {
    path: 'register',
    loadChildren: () => import('./pages/pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },


  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboards/analytics',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),  canActivate: [AdminGuard]
      },
      {
        path: 'penalties',
        loadChildren: () => import('./penalties/penalties.module').then(m => m.PenaltiesModule),  //canActivate: [AdminGuard]
      },
    
      {  path: 'emp',
      loadChildren: () => import('./employees/employees.module').then(m => m.EmployeesModule) ,canActivate: [AuthGuard]
    },
    {  path: 'dep',
    loadChildren: () => import('./departments/departments.module').then(m => m.DepartmentsModule) ,canActivate: [AuthGuard]
  },
  {  path: 'plan',
      loadChildren: () => import('./plan/plan.module').then(m => m.PlanModule) ,canActivate: [AuthGuard]
    },
    {  path: 'holiday',
    loadChildren: () => import('./holiday/holiday.module').then(m => m.HolidayModule) ,canActivate: [AuthGuard]
  },  {  path: 'leaves',
  loadChildren: () => import('./leaves/leaves.module').then(m => m.LeavesModule) ,canActivate: [AuthGuard]
},  {  path: 'leaveBalance',
loadChildren: () => import('./leave-balance/leave-balance.module').then(m => m.LeaveBalanceModule) ,canActivate: [AuthGuard]
},
{  path: 'logs',
loadChildren: () => import('./log-attend/log-attend.module').then(m => m.LogAttendModule) ,canActivate: [AuthGuard]
},
{  path: 'designation',
loadChildren: () => import('./designation/designation.module').then(m => m.DesignationModule) ,canActivate: [AuthGuard]
},

{
  path: 'archive',
  loadChildren: () => import('./archive/archive.module').then(m => m.archiveModule),
},
{
  path: 'archived-mail-view',
  loadChildren: () => import('./archived-mail-view/archived-mail-view.module').then(m => m.ArchivedMailViewModule),
},
{
  path: 'audit-interface',
  loadChildren: () => import('./audit_interface/audit_interface.module').then(m => m.AuditInterfaceModule),
},
{
  path: 'financial-management',
  loadChildren: () => import('./financial-management/financial-management.module').then(m => m.FinancialManagementModule),
},
{
  path: 'financial-reports',
  loadChildren: () => import('./financial-reports/financial-reports.module').then(m => m.FinancialReportsModule),
},
{
  path: 'inventory',
  loadChildren: () => import('./Inventory/Inventory.module').then(m => m.InventoryModule),
},
{
  path: 'outgoing-mail-archiving',
  loadChildren: () => import('./outgoing-mail-archiving/outgoing-mail-archiving.module').then(m => m.OutgoingMailArchivingModule),
},
{
  path: 'permissions-management',
  loadChildren: () => import('./permissions-management/permissions-management.module').then(m => m.PermissionsMnagementModule),
},
{
  path: 'product',
  loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
},

{
  path: 'allowance-management',
  loadChildren: () => import('./allowance-management/allowance-management.module').then(m => m.AllowanceManagementModule),
},
{
  path: 'approvals',
  loadChildren: () => import('./approvals/approvals.module').then(m => m.ApprovalsModule),
},

{
  path: 'promotion-management',
  loadChildren: () => import('./promotion-management/promotion-management.module').then(m => m.PromotionManagementModule),
},
{
  path: 'report',
  loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
},
{
  path: 'salary-settings',
  loadChildren: () => import('./salary-settings/salary-settings.module').then(m => m.SalarySettingsModule),

},

 

       {
        path: 'invoice',
        loadChildren: () => import('./pages/pages/invoice/invoice.module').then(m => m.InvoiceModule)
      },{path:'HightCharts' , component:HightChartsComponent},
      {
        path: '',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),canActivate: [AuthGuard]
      },
      {
        path: 'user',
         loadChildren: () => import('./user/user.module').then(m => m.UserModule), canActivate: [AuthGuard]
      }    
      ,
      {
        path: 'Admin',
         loadChildren: () => import('./admin/admin-routing.module').then(m => m.AdminRoutingModule), canActivate: [AuthGuard]
      }    
,
 
      {
        path: 'apps',
        children: [
          {
            path: 'chat',
            loadChildren: () => import('./pages/apps/chat/chat.module').then(m => m.ChatModule),
            data: {
              toolbarShadowEnabled: true
            }
          }

          ,
          {
            path: 'mail',
            loadChildren: () => import('./pages/apps/mail/mail.module').then(m => m.MailModule),
            data: {
              toolbarShadowEnabled: true,
              scrollDisabled: true
            }
          },
          {
            path: 'social',
            loadChildren: () => import('./pages/apps/social/social.module').then(m => m.SocialModule)
          },
       
          {
            path: 'contacts',
            loadChildren: () => import('./pages/apps/contacts/contacts.module').then(m => m.ContactsModule)
          },
          {
            path: 'calendar',
            loadChildren: () => import('./pages/apps/calendar/calendar.module').then(m => m.CalendarModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'aio-table',
            loadChildren: () => import('./pages/apps/aio-table/aio-table.module').then(m => m.AioTableModule),
          },
     

          {
            path: 'challange-center',
            loadChildren: () => import('./pages/apps/challange-center/challange-center.module').then(m => m.ChallangeCenterModule),
          },
          {
            path: 'scrumboard',
            loadChildren: () => import('./pages/apps/scrumboard/scrumboard.module').then(m => m.ScrumboardModule),
          },
          {
            path: 'editor',
            loadChildren: () => import('./pages/apps/editor/editor.module').then(m => m.EditorModule),
          },
        ]
      },
      {
        path: 'pages',
        children: [
          {
            path: 'pricing',
            loadChildren: () => import('./pages/pages/pricing/pricing.module').then(m => m.PricingModule)
          },
          {
            path: 'faq',
            loadChildren: () => import('./pages/pages/faq/faq.module').then(m => m.FaqModule)
          },
          {
            path: 'guides',
            loadChildren: () => import('./pages/pages/guides/guides.module').then(m => m.GuidesModule)
          },

          {
            path: 'error-404',
            loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
          },
          {
            path: 'error-500',
            loadChildren: () => import('./pages/pages/errors/error-500/error-500.module').then(m => m.Error500Module)
          }
        ]
      },
      {
        path: 'ui',
        children: [
          {
            path: 'components',
            loadChildren: () => import('./pages/ui/components/components.module').then(m => m.ComponentsModule),
          },
          {
            path: 'forms/form-elements',
            loadChildren: () => import('./pages/ui/forms/form-elements/form-elements.module').then(m => m.FormElementsModule),
            data: {
              containerEnabled: true
            }
          },
          {
            path: 'forms/form-wizard',
            loadChildren: () => import('./pages/ui/forms/form-wizard/form-wizard.module').then(m => m.FormWizardModule),
            data: {
              containerEnabled: true
            }
          },
          {
            path: 'icons',
            loadChildren: () => import('./pages/ui/icons/icons.module').then(m => m.IconsModule)
          },
          {
            path: 'page-layouts',
            loadChildren: () => import('./pages/ui/page-layouts/page-layouts.module').then(m => m.PageLayoutsModule),
          },
        ]
      },
      {
        path: 'documentation',
        loadChildren: () => import('./pages/documentation/documentation.module').then(m => m.DocumentationModule),
      },
      {
        path: '**',
        loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      }
    ]  //,canActivate: [AuthGuard]
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule, QuicklinkModule]

})
export class AppRoutingModule {
}
