import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { TokenceptorService } from './services/tokenceptor.service';
import { LoadingInterceptor } from 'src/assets/loadingSys/loading.interceptors';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PreloadingStrategy } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { TopicFormComponent } from './topic-form/topic-form.component';
import { OverTimeOrderComponent } from './over-time-order/over-time-order.component';
import { SharedModule } from './ShareComponent/shared.module';
import { UserService } from './user/user.service';

@NgModule({
  declarations: [AppComponent, TopicFormComponent, OverTimeOrderComponent 

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,BrowserAnimationsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    VexModule,
    CustomLayoutModule,
    FormsModule, // Add FormsModule here
    MatSnackBarModule,
    ToastrModule.forRoot(
      { timeOut: 3500,
       positionClass: 'toast-bottom-center',
       preventDuplicates: true,}
     )
  ],
    providers: [

  // { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  // ,{ provide: PreloadingStrategy, useClass: QuicklinkStrategy },

     { provide: HTTP_INTERCEPTORS,
      useClass: TokenceptorService,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    {provide:HTTP_INTERCEPTORS,useClass: ErrorInterceptor,multi:true},
     { provide: PreloadingStrategy, useClass: QuicklinkStrategy },
     // Include the ErrorService in the providers
  ],

  bootstrap: [AppComponent]
})
export class AppModule {}
